@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;800&display=swap");
h1,
h3,
button {
  font-family: Montserrat !important;
}
.logo {
  width: 100%;
  max-width: 170px !important;
}
.maxbtn {
  color: white;
  border: 1px solid white;
  padding: 2px 4px;
  cursor: pointer;
  border-radius: 5px;
}
.greenbg {
  background-color: #046855;
}
.main-contain {
  max-width: 100%;
  width: 1750px !important;
}
.icon {
  display: flex;
  max-width: 100%;
  width: 1125px;
  flex-direction: row;
  justify-content: flex-end;
}
.ico {
  font-size: 25px;
  color: white;
}
.ico1 {
  font-size: 30px;
  color: #83b677;
}
.section-bg {
  background-image: url(../Images/Background-01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}
.logo-1 {
  max-width: 100%;
  width: 130px !important;
}
.heading {
  font-size: 45px;
  font-weight: 900;
  color: white;
}
.mint {
  background-color: #472a6d;
  border-radius: 50px;
}
.sub-heading {
  font-size: 25px;
  font-weight: 500;
}
.btn-1 {
  background-color: #93d9dd;
  font-weight: 500;
  font-size: 19px;
  color: #472a6d;
  width: 170px;
  height: 60px;
  border: none;
  border-radius: 10px;
  line-height: 23px;
  cursor: pointer;
}
.btn-2 {
  background-color: white !important;
  font-weight: 500 !important;
  font-size: 20px;
  color: #472a6d !important;
  width: 170px;
  height: 60px;
  border: none !important;
  border-radius: 10px !important;
  line-height: 23px;
  cursor: pointer;
}
.btn-2 span {
  color: #472a6d !important;
}
.btn-2 img {
  display: none;
  margin: 0 !important;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.count {
  background-color: #93d9dd;
  border: none;
  border-radius: 100%;
  width: 40px;
  cursor: pointer;
  height: 40px;
  padding-bottom: 6px;
  color: #472a6d;
  text-align: center;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.count span {
  /* display: block; */
  /* width: 100%; */
  line-height: 10px;
  font-size: 35px;
  font-weight: 500;
}
.plus {
  padding-bottom: 0px;
}
@media only screen and (max-width: 600px) {
  .heading {
    font-size: 35px;
  }
}
@media only screen and (max-width: 600px) {
  .sub-heading {
    font-size: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .btn-1 {
    font-size: 15px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .btn-2 {
    font-size: 15px;
    margin-top: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .logo-1 {
    max-width: 100%;
    width: 100px !important;
  }
}
@media only screen and (max-width: 600px) {
  .icon {
    max-width: 100%;
    width: 340px !important;
    justify-content: center;
    margin-top: 40px;
  }
}
/* @media only screen and (max-width: 600px) {
  .logo {
    max-width: 100%;
    width: 130px !important;
  }
} */
.icot {
  justify-content: center;
}
.buttonsborder {
  border: 2px solid white;
  max-width: 190px;
  /* padding: 10px; */
  border-radius: 10px;
  margin: auto;
}
